import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
export default function Login() {
  return (
    <Box className="bg-wallp">
      <Box className="bg-white2">
        <Box className="logo-holder">
          <Box className="logo"></Box>
        </Box>

        <Box className="input-holder">
          <TextField
            id="filled-basic"
            label="Username / Email"
            variant="filled"
          />
        </Box>
        <Box className="input-holder">
          <Link
            className="link"
            to="/passw"
            style={{ backgroundColor: "#fff" }}
          >
            <Button className="btn-hold" variant="contained">
              Next
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
