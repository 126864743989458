import React from "react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import DigitalImg from "../../asset/Dashboard.png";

export default function Digital() {
  return (
    <div>
      <Grid container spacing={1}>
        <Grid
          className="mb-30 card-grid1 ff-m"
          sx={{ position: "relative" }}
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <Box className="titleRisk">
            <img src={DigitalImg} class="img-fluid-menu" alt="..."></img>
            {/* <iframe
              height="600px"
              src={DigitalImg}
              frameborder="0"
              allowfullscreen
              style={{
                marginLeft: "-15px",
                marginTop: "-15px",
                width: "-webkit-fill-available",
              }}
            /> */}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
