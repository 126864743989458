import React from "react";
import BarChart1 from "../BarChart/BarChart1";
import LineCharts from "../LineChart/LineCharts";
import Piechart from "../Pichart/Piechart";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
export default function Analytics() {
  const classes = useStyles();
  return (
    <>
      <Grid container>
        <Grid
          className="mb-30 card-grid1 ff-m"
          sx={{ position: "relative" }}
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <div style={{ marginTop: "5%" }}></div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}></Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <LineCharts />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <BarChart1 />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Piechart />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
