import React from "react";

import Box from "@mui/material/Box";

import SideSlider from "../../pages/sideSlider/SideSlider";
import SideSlider2 from "../../pages/sideSlider/SideSlider2";
import SideSlider3 from "../../pages/sideSlider/SideSlider3";
import SideSlider4 from "../../pages/sideSlider/SideSlider4";
import SideContent from "../../pages/sideContent/SideContent";

export default function NewsSection() {
  return (
    <div>
      <Box sx={{ position: "absolute", width: "100%" }}>
        <Box>
          <SideSlider />
        </Box>
        <Box>
          <SideSlider2 />
        </Box>
        <Box>
          <SideSlider3 />
        </Box>
        <Box>
          <SideSlider4 />
        </Box>
        <Box className="mb-48">
          <SideContent />
        </Box>
      </Box>
    </div>
  );
}
