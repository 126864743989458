import { Box } from "@mui/material";
import React from "react";
import righticon from "../../asset/icon/ArrowRight.png";
import righticon1 from "../../asset/icon/yellow-righ.png";

export default function SideContent() {
  return (
    <>
      <Box className="d-flex justify-center">
        <Box className="yell-box">
          <Box className="box-til">How can we help?</Box>
          <img className="righticon" src={righticon} alt="" />
        </Box>
      </Box>
      <Box className="d-flex justify-center mt-18">
        <Box className="side-til">Did You Know?</Box>
      </Box>

      <Box className="d-flex space-around mt-14">
        <Box className="d-flex space-between w-80">
          <Box className="box-til col-yello">Ventiv University</Box>
          <Box>
            <img className="righticon" src={righticon1} alt="" />
          </Box>
        </Box>
      </Box>

      <Box className="d-flex space-around mt-14">
        <Box className="d-flex space-between w-80">
          <Box className="box-til col-yello">Industrial News</Box>
          <Box>
            <img className="righticon" src={righticon1} alt="" />
          </Box>
        </Box>
      </Box>

      <Box className="d-flex space-around mt-14">
        <Box className="d-flex space-between w-80">
          <Box className="box-til col-yello">Industry Trends</Box>
          <Box>
            <img className="righticon" src={righticon1} alt="" />
          </Box>
        </Box>
      </Box>

      <Box className="d-flex space-around mt-14">
        <Box className="d-flex space-between w-80">
          <Box className="box-til col-yello">Industry Events</Box>
          <Box>
            <img className="righticon" src={righticon1} alt="" />
          </Box>
        </Box>
      </Box>

      <Box className="d-flex space-around mt-14">
        <Box className="d-flex space-between w-80">
          <Box className="box-til col-yello">Others</Box>
          <Box>
            <img className="righticon" src={righticon1} alt="" />
          </Box>
        </Box>
      </Box>
    </>
  );
}
