import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination } from "swiper";
import { Box } from "@mui/material";
import "./slider.css";

export default function SliderCen({ rightOpen, leftOpen }) {
  return (
    <>
      <Box className="card-til mt-14">Solutions</Box>
      <Swiper
        navigation={true}
        spaceBetween={15}
        pagination={true}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          740: {
            slidesPerView: 2,
          },
          // when window width is >= 768px
          1300: {
            slidesPerView: 3,
          },
        }}
        modules={[Navigation, Pagination]}
        className="mySwiper mySwpierCen"
      >
        <SwiperSlide>
          <Box
            className="d-flex space-between"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.06) !important",
              gap: "12px",
            }}
          >
            <Box className="bulb"></Box>
            <Box
              sx={{
                width: "120px",
              }}
            >
              <Box
                sx={{
                  textAlign: "left",
                  ms: 2,
                  mt: 3,
                }}
              >
                <Box className="title-sli">Business Intelligence</Box>
                <Box className="sub-title">Risk</Box>
                <Box className="sub-title">Claims</Box>
                <Box className="sub-title">Digital</Box>
              </Box>
            </Box>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box
            className="d-flex space-between"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.06) !important",
              gap: "12px",
            }}
          >
            <Box className="bulb-1"></Box>
            <Box
              sx={{
                width: "120px",
              }}
            >
              <Box
                sx={{
                  textAlign: "left",
                  ms: 2,
                  mt: 3,
                }}
              >
                <Box className="title-sli">Self Discovery</Box>
                <Box className="sub-title">Data Discovery</Box>
                <Box className="sub-title">Exploration</Box>
              </Box>
            </Box>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box
            className="d-flex space-between"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.06) !important",
              gap: "12px",
            }}
          >
            <Box className="bulb-3"></Box>
            <Box
              sx={{
                width: "120px",
              }}
            >
              <Box
                sx={{
                  textAlign: "left",
                  ms: 2,
                  mt: 3,
                }}
              >
                <Box className="title-sli">Business Intelligence</Box>
                <Box className="sub-title">Risk</Box>
                <Box className="sub-title">Claims</Box>
                <Box className="sub-title">Digital</Box>
              </Box>
            </Box>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <Box
            className="d-flex space-between"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.06) !important",
              gap: "12px",
            }}
          >
            <Box className="bulb-2"></Box>
            <Box
              sx={{
                width: "120px",
              }}
            >
              <Box
                sx={{
                  textAlign: "left",
                  ms: 2,
                  mt: 3,
                }}
              >
                <Box className="title-sli">Business Intelligence</Box>
                <Box className="sub-title">Risk</Box>
                <Box className="sub-title">Claims</Box>
                <Box className="sub-title">Digital</Box>
              </Box>
            </Box>
          </Box>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
