import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import dot1 from "../../asset/icon/dot1.png";
import dot2 from "../../asset/icon/dot2.png";
import dot3 from "../../asset/icon/dot3.png";
import dot4 from "../../asset/icon/dot4.png";
import BarChart1 from "../BarChart/BarChart1";
import LineCharts from "../LineChart/LineCharts";
import DoubleArrowOutlinedIcon from "@material-ui/icons/DoubleArrowOutlined";
import Tooltip from "@material-ui/core/Tooltip";
export default function Benchmark() {
  const [showBenchmark, setShowBenchmark] = React.useState(false);
  const [showBenchmarkSecond, setShowBenchmarkSecond] = React.useState(false);

  const changeBenchMark = () => {
    setShowBenchmark(!showBenchmark);
  };

  const changeBenchMarkSecond = () => {
    setShowBenchmarkSecond(!showBenchmarkSecond);
  };
  return (
    <>
      <Grid container spacing={2}>
        {showBenchmarkSecond === false && (
          <Grid
            item
            lg={showBenchmark ? 12 : 7}
            md={12}
            sm={12}
            xs={12}
            style={{ paddingLeft: "20px" }}
          >
            <Box className="ben-holder">Ventiv Benchmarking</Box>
            <Box className="ben-holder2">
              <Box>Ventiv Client</Box>
              <Box className="tble-container">
                <Grid className="tble-head" container spacing={2}>
                  <Grid className="grid-width" item lg={5} xs={5}></Grid>
                  <Grid item lg={2} xs={2}>
                    Avg. Cost
                  </Grid>
                  <Grid item lg={2} xs={2}>
                    Retailers
                  </Grid>
                  <Grid item lg={2} xs={2}>
                    Diff.
                  </Grid>
                </Grid>
                <Grid className="tble-body" container spacing={2}>
                  <Grid className="grid-width" item lg={5} xs={5}>
                    Workers Compensation
                  </Grid>
                  <Grid item lg={2} xs={2}>
                    $120
                  </Grid>
                  <Grid item lg={2} xs={2}>
                    $105-$115
                  </Grid>
                  <Grid className="light-red" item lg={2} xs={2}>
                    $105-$115
                  </Grid>
                </Grid>
                <Grid className="tble-body" container spacing={2}>
                  <Grid className="grid-width" item lg={5} xs={5}>
                    Liability
                  </Grid>
                  <Grid item lg={2} xs={2}>
                    $56
                  </Grid>
                  <Grid item lg={2} xs={2}>
                    $47-$93
                  </Grid>
                  <Grid className="light-red" item lg={2} xs={2}>
                    +27.43%
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box className="d-flex space-around worker-container">
              <Box className="title-sli">Workers Compensation</Box>
              <Box className="avg-ho">
                <span className="col-red">+29%</span> Your avg. claim cost is
                higher on average
              </Box>
            </Box>
            <Box>
              <BarChart1 />
            </Box>

            <Box className="progess-hold">
              <Box className="d-flex align-center mb-10 rou-padding">
                <Box className="red-round">
                  <Box className="progess-inner">-15%</Box>
                </Box>
                <Box>
                  <Box className="progess-til">Avg. Close Days</Box>
                  <Box className="sub-progess-til">31 Competitors</Box>
                </Box>
              </Box>
              <Box className="d-flex align-center mb-10 rou-padding">
                <Box className="blue-round">
                  <Box className="progess-inner">-15%</Box>
                </Box>
                <Box>
                  <Box className="progess-til">Avg. Close Days</Box>
                  <Box className="sub-progess-til">31 Competitors</Box>
                </Box>
              </Box>
              <Box className="d-flex align-center mb-10 rou-padding">
                <Box className="yellow-round">
                  <Box className="progess-inner">-15%</Box>
                </Box>
                <Box>
                  <Box className="progess-til">Avg. Close Days</Box>
                  <Box className="sub-progess-til">31 Competitors</Box>
                </Box>
              </Box>
              <Box
                onClick={changeBenchMark}
                className={
                  showBenchmark === true
                    ? "right-arrow m-t-35"
                    : "float-right m-t-35"
                }
              >
                <Tooltip
                  title={showBenchmark === false ? "Expand" : "Scale down"}
                  aria-label="add"
                >
                  <DoubleArrowOutlinedIcon />
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        )}

        {showBenchmark === false && (
          <Grid item lg={showBenchmarkSecond ? 12 : 5} md={12} sm={12} xs={12}>
            <Box className="ben-holder21">Benchmarking</Box>
            <Box className="ben-holder212">
              <Box className="avr-clai">
                <Box>Average Claim Cost</Box>
                <Box>2022</Box>
              </Box>
              <Box className="Indus">Industry Type - Retail Sector</Box>
            </Box>
            <Box className="graph-holder">
              <Box>
                <Box className="graph2">
                  <Box>
                    <Box
                      className="title-sli"
                      sx={{
                        margin: "15px 0 0 0",
                        width: "70%",
                      }}
                    >
                      Workers Compensation
                    </Box>
                  </Box>
                  <Box className="tble-container table2">
                    <Grid className="tble-head" container spacing={2}>
                      <Grid item lg={1}></Grid>
                      <Grid item lg={6}>
                        Competitors
                      </Grid>
                      <Grid item lg={5}>
                        Avg. Cost
                      </Grid>
                    </Grid>
                    <Grid className="tble-body" container spacing={2}>
                      <Grid item className="dt1" lg={1}>
                        <img src={dot1} alt="" />
                      </Grid>
                      <Grid item lg={6}>
                        Our Company
                      </Grid>
                      <Grid item lg={5}>
                        $340
                      </Grid>
                    </Grid>
                    <Grid className="tble-body" container spacing={2}>
                      <Grid item className="dt1" lg={1}>
                        <img src={dot2} alt="" />
                      </Grid>
                      <Grid item lg={6}>
                        Retailer A
                      </Grid>
                      <Grid item lg={5}>
                        $120
                      </Grid>
                    </Grid>
                    <Grid className="tble-body" container spacing={2}>
                      <Grid item className="dt1" lg={1}>
                        <img src={dot3} alt="" />
                      </Grid>
                      <Grid item lg={6}>
                        Retailer B
                      </Grid>
                      <Grid item lg={5}>
                        $120
                      </Grid>
                    </Grid>
                    <Grid className="tble-body" container spacing={2}>
                      <Grid item className="dt1" lg={1}>
                        <img src={dot4} alt="" />
                      </Grid>
                      <Grid item lg={6}>
                        Retailer C
                      </Grid>
                      <Grid item lg={5}>
                        $120
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box>
                  <LineCharts />
                </Box>
              </Box>
              <Box
                onClick={changeBenchMarkSecond}
                className={
                  showBenchmarkSecond === true ? "right-arrow" : "float-right"
                }
              >
                <Tooltip
                  title={
                    showBenchmarkSecond === false ? "Expand" : "Scale down"
                  }
                  aria-label="add"
                >
                  <DoubleArrowOutlinedIcon />
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
}
