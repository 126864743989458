import React from "react";
import Box from "@mui/material/Box";
import SliderCen from "../../pages/slider/SliderCen";
import Benchmark from "../../pages/Benchmark/Benchmark";
import riskIcon from "../../asset/risk_icon.png";
import rightA from "../../asset/icon/ArrowRight.png";
import { Link } from "react-router-dom";

export default function Dashboard({ update, rightOpen, leftOpen,switchShow }) {
  console.log(rightOpen, leftOpen);
  return (
    <div class="container" style={{ marginLeft: "-23px" }}>
      <div
        class={
          rightOpen === false && leftOpen === false
            ? "row row-cols-2 row-cols-lg-2 row-mr-162"
            : rightOpen === true && leftOpen === false
            ? "row row-cols-2 row-cols-lg-2 row-mr-141"
            : "row row-cols-2 row-cols-lg-2 row-mr-85"
        }
      >
        {
          switchShow ?
          <>
          <div class={rightOpen ? "col-3 col-lg-2" : "col-3 col-lg-3"}>
          {" "}
          
            <Box className="risk" onClick={() => update(4)}>
              <img className="rishIcon" src={riskIcon} alt="" />
              <Box className="titleRisk">Risk</Box>
              <Box className="acessHolder">
                <Box className="acess">Access</Box>
                <img src={rightA} alt="" />
              </Box>
            </Box>
        </div>
        <div class={rightOpen ? "col-3 col-lg-2" : "col-3 col-lg-3"}>
          
            <Box className="risk" onClick={() => update(3)}>
              <img className="rishIcon" src={riskIcon} alt="" />
              <Box className="titleRisk">Risk Mobile</Box>
              <Box className="acessHolder">
                <Box className="acess">Access</Box>
                <img src={rightA} alt="" />
              </Box>
            </Box>
        </div>
        <div class={rightOpen ? "col-3 col-lg-2" : "col-3 col-lg-3"}>
        
          <Box className="risk" onClick={() => update(2)}>
            <img className="rishIcon" src={riskIcon} alt="" />
            <Box className="titleRisk">Risk Analytics</Box>
            <Box className="acessHolder">
              <Box className="acess">Access</Box>
              <img src={rightA} alt="" />
            </Box>
          </Box>
        </div>
        <div class={rightOpen ? "col-3 col-lg-2" : "col-3 col-lg-3"}>
          
            <Box className="risk" onClick={() => update(6)}>
              <img className="rishIcon" src={riskIcon} alt="" />
              <Box className="titleRisk">Claims</Box>
              <Box className="acessHolder">
                <Box className="acess">Access</Box>
                <img src={rightA} alt="" />
              </Box>
            </Box>
        </div>
        <div class={rightOpen ? "col-3 col-lg-2" : "col-3 col-lg-3"}>
         
            <Box className="risk" onClick={() => update(7)}>
              <img className="rishIcon" src={riskIcon} alt="" />
              <Box className="titleRisk">Claims Mobile</Box>
              <Box className="acessHolder">
                <Box className="acess">Access</Box>
                <img src={rightA} alt="" />
              </Box>
            </Box>
        </div>
        <div class={rightOpen ? "col-3 col-lg-2" : "col-3 col-lg-3"}>
          {/* <Link
            to={"/claims-analytics"}
            target="_blank"
            style={{ textDecoration: "none" }}
          > */}
          <Box className="risk" onClick={() => update(1)}>
            <img className="rishIcon" src={riskIcon} alt="" />
            <Box className="titleRisk">Claims Analytics</Box>
            <Box className="acessHolder">
              <Box className="acess">Access</Box>
              <img src={rightA} alt="" />
            </Box>
          </Box>
          {/* </Link> */}
        </div>
          </>
          :
          <>
          <div class={rightOpen ? "col-3 col-lg-2" : "col-3 col-lg-3"}>
          {" "}
          <Link
            className="menuitem"
            to={"/risk"}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <Box className="risk" onClick={() => update(4)}>
              <img className="rishIcon" src={riskIcon} alt="" />
              <Box className="titleRisk">Risk</Box>
              <Box className="acessHolder">
                <Box className="acess">Access</Box>
                <img src={rightA} alt="" />
              </Box>
            </Box>
          </Link>
        </div>
        <div class={rightOpen ? "col-3 col-lg-2" : "col-3 col-lg-3"}>
          <Link
            to={"/digital"}
            className="menuitem"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <Box className="risk" onClick={() => update(3)}>
              <img className="rishIcon" src={riskIcon} alt="" />
              <Box className="titleRisk">Risk Mobile</Box>
              <Box className="acessHolder">
                <Box className="acess">Access</Box>
                <img src={rightA} alt="" />
              </Box>
            </Box>
          </Link>
        </div>
        <div class={rightOpen ? "col-3 col-lg-2" : "col-3 col-lg-3"}>
          {/* <Link
            to={"/risk-analytics"}
            className="menuitem"
            target="_blank"
            style={{ textDecoration: "none" }}
          > */}
          <Box className="risk" onClick={() => update(2)}>
            <img className="rishIcon" src={riskIcon} alt="" />
            <Box className="titleRisk">Risk Analytics</Box>
            <Box className="acessHolder">
              <Box className="acess">Access</Box>
              <img src={rightA} alt="" />
            </Box>
          </Box>
          {/* </Link> */}
        </div>
        <div class={rightOpen ? "col-3 col-lg-2" : "col-3 col-lg-3"}>
          <Link
            className="menuitem"
            to={"/claims"}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <Box className="risk" onClick={() => update(6)}>
              <img className="rishIcon" src={riskIcon} alt="" />
              <Box className="titleRisk">Claims</Box>
              <Box className="acessHolder">
                <Box className="acess">Access</Box>
                <img src={rightA} alt="" />
              </Box>
            </Box>
          </Link>
        </div>
        <div class={rightOpen ? "col-3 col-lg-2" : "col-3 col-lg-3"}>
          <Link
            to={"/digital"}
            className="menuitem"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <Box className="risk" onClick={() => update(7)}>
              <img className="rishIcon" src={riskIcon} alt="" />
              <Box className="titleRisk">Claims Mobile</Box>
              <Box className="acessHolder">
                <Box className="acess">Access</Box>
                <img src={rightA} alt="" />
              </Box>
            </Box>
          </Link>
        </div>
        <div class={rightOpen ? "col-3 col-lg-2" : "col-3 col-lg-3"}>
          {/* <Link
            to={"/claims-analytics"}
            target="_blank"
            style={{ textDecoration: "none" }}
          > */}
          <Box className="risk" onClick={() => update(1)}>
            <img className="rishIcon" src={riskIcon} alt="" />
            <Box className="titleRisk">Claims Analytics</Box>
            <Box className="acessHolder">
              <Box className="acess">Access</Box>
              <img src={rightA} alt="" />
            </Box>
          </Box>
          {/* </Link> */}
        </div>
          </>
        }
      </div>
      {rightOpen === true && leftOpen === false ? (
        <Box
          sx={{
            position: "absolute",
            width: "82%",
          }}
        >
          <Box>
            <SliderCen rightOpen={rightOpen} leftOpen={leftOpen} />
          </Box>
          {rightOpen === false && leftOpen === false ? (
            <Box className="card-grid w-109">
              <Benchmark />
            </Box>
          ) : (
            <Box className={rightOpen ? "card-grid w-100" : "card-grid w-105"}>
              <Benchmark />
            </Box>
          )}
        </Box>
      ) : rightOpen === false && leftOpen === true ? (
        <Box
          sx={{
            position: "absolute",
            width: "87%",
          }}
        >
          <Box>
            <SliderCen rightOpen={rightOpen} leftOpen={leftOpen} />
          </Box>
          {rightOpen === false && leftOpen === false ? (
            <Box className="card-grid w-109">
              <Benchmark />
            </Box>
          ) : (
            <Box className={rightOpen ? "card-grid w-100" : "card-grid w-105"}>
              <Benchmark />
            </Box>
          )}
        </Box>
      ) : rightOpen && leftOpen ? (
        <Box
          sx={{
            position: "absolute",
            width: "74%",
          }}
        >
          <Box>
            <SliderCen rightOpen={rightOpen} leftOpen={leftOpen} />
          </Box>
          {rightOpen === false && leftOpen === false ? (
            <Box className="card-grid w-109">
              <Benchmark />
            </Box>
          ) : (
            <Box className={rightOpen ? "card-grid w-100" : "card-grid w-105"}>
              <Benchmark />
            </Box>
          )}
        </Box>
      ) : rightOpen === false && leftOpen === false ? (
        <Box
          sx={{
            position: "absolute",
            width: "97%",
          }}
        >
          <Box>
            <SliderCen rightOpen={rightOpen} leftOpen={leftOpen} />
          </Box>
          {rightOpen === false && leftOpen === false ? (
            <Box className="card-grid w-109">
              <Benchmark />
            </Box>
          ) : (
            <Box className={rightOpen ? "card-grid w-100" : "card-grid w-105"}>
              <Benchmark />
            </Box>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            position: "absolute",
            width: "74%",
          }}
        >
          <Box>
            <SliderCen rightOpen={rightOpen} leftOpen={leftOpen} />
          </Box>
          {rightOpen === false && leftOpen === false ? (
            <Box className="card-grid w-109">
              <Benchmark />
            </Box>
          ) : (
            <Box className={rightOpen ? "card-grid w-100" : "card-grid w-105"}>
              <Benchmark />
            </Box>
          )}
        </Box>
      )}
    </div>
  );
}
