import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
export default function Authentication() {
  return (
    <Box className="bg-wallp">
      <Box className="bg-white3">
        <Box
          sx={{
            mt: 4,
          }}
          className="logo-holder"
        >
          <Box className="logo"></Box>
        </Box>
        <Box className="input-holder tle-20-bld">2 Factor Authentication</Box>
        <Box className="card-til verif">Enter the verification code</Box>

        <Box className="verif-code">
          A verification code has just been sent to your e-mail.
        </Box>

        <Box className="input-holder">
          <TextField
            id="filled-basic"
            label="OTP"
            variant="filled"
            type="password"
          />
        </Box>
        <Box className="input-holder">
          <Link
            className="link"
            to="/navtabs"
            style={{ backgroundColor: "#fff" }}
          >
            <Button className="btn-hold" variant="contained">
              Done
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
