import React from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
export default function Language() {
  return (
    <Box className="bg-wallp">
      <Box className="bg-white">
        <Box className="bg-title  px-45">Choose Your Prefered Language</Box>

        <Box className="eng-hold">
          <Box className="sm-title pt-20">🇬🇧 English</Box>
          <Box className="icon-right"></Box>
        </Box>

        <Box className="sub-lang">🇧🇬 Bulgarian</Box>

        <Box className="sub-lang">🇨🇳 Chinese</Box>

        <Box className="sub-lang">🇨🇿 Czech</Box>

        <Box className="sub-lang">🇳🇱 Dutch</Box>

        <Box className="sub-lang">🇫🇷 French</Box>

        <Box className="sub-lang">🇩🇪 German</Box>

        <Box className="sub-lang">🇮🇳 Hindi</Box>

        <Box className="input-holder">
          <Link
            className="link"
            to="/login"
            style={{ backgroundColor: "#fff" }}
          >
            <Button className="btn-hold1" variant="contained">
              Next
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
