import React from 'react'
import {LineChart, Line, ResponsiveContainer , XAxis} from "recharts"

export default function LineCharts() {
    const LineData = [
        {
            month:"Jan",
            yellow:7,
            green:8,
            grey:6,
            black:4    
        },
        {
            month:"Mar",
            yellow:5,
            green:9,
            grey:5,
            black:2    
        },
        {
            month:"May",
            yellow:9,
            green:5,
            grey:6,
            black:4    
        },
        {
            month:"Jun",
            yellow:5,
            green:9,
            grey:5,
            black:2   
        },
        {
            month:"Sep",
            yellow:7,
            green:8,
            grey:6,
            black:4    
        },
    ]
  return (
    <>
    <ResponsiveContainer width="100%" height='100%' aspect={2}>
        <LineChart data={LineData} margin={{ top: 5, right: 30, left: 20, bottom: 0 }}>
            <XAxis dataKey="month" interval="preserveStartEnd"/>
            <Line type='monotone' dataKey="yellow" stroke='#F5B536'/> 
            <Line type='monotone' dataKey="green" stroke='#007B7F'/>
            <Line type='monotone' dataKey="green" stroke='#D9D9D9'/>
            <Line type='monotone' dataKey="black" stroke='#2F3636'/>
        </LineChart>

    </ResponsiveContainer>
    </>
  )
}
