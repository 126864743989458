import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import poi from "../../asset/icon/Poi.png";

import bgSlide from "../../asset/icon/photo-1661956601031-4cf09efadfce.avif";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import "./styles.css";
import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination } from "swiper";
import { Box } from "@mui/material";

export default function SideSlider3() {
  return (
    <>
      <Swiper
        navigation={true}
        pagination={{
          type: "fraction",
        }}
        modules={[Navigation, Pagination]}
        className="mySwiper myswiper3"
      >
        <SwiperSlide>
          <Box className="slide-img2">
            <Box></Box>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <Box className="slide-img4">
            <Box className="slider-tilte px-4">
              Ventiv Technology Included in 2022 RMIS Panorama by AMRAE
            </Box>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box className="slide-img1">
            <Box className="slider-tilte px-4">
              Ventiv Technology Included in 2022 RMIS Panorama by AMRAE
            </Box>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box className="slide-img3">
            <Box className="slider-tilte px-4">
              Ventiv Technology Included in 2022 RMIS Panorama by AMRAE
            </Box>
          </Box>
        </SwiperSlide>
      </Swiper>
      <Box className="box-cont-holder">
        <Box className="side-content-box">
          <Box className="tle-12-bld-7">Ventiv Experience 2022 Sydney</Box>
          <Box className="sb-til">
            <img src={poi} /> &nbsp; Fullerton Hotel Sydney, Australia
          </Box>
          <Box className="sb-til">
            <img src={poi} /> &nbsp; 26 October, 2022
          </Box>
        </Box>
      </Box>
    </>
  );
}
