import React from "react";
import { PieChart, Pie, ResponsiveContainer } from "recharts";
export default function Piechart() {
  const data01 = [
    { name: "Active Campagins", value: 90 },
    { name: "Inactive Campagins", value: 25 },
    { name: "ICPs with no campagins", value: 10 },
  ];
  const data02 = [
    { name: "Active Campagins", value: 20 },
    { name: "Inactive Campagins", value: 45 },
    { name: "ICPs with no campagins", value: 10 },
  ];

  return (
    <div style={{ width: "100%", height: 420 }}>
      <ResponsiveContainer>
        <PieChart width={730} height={250}>
          <Pie
            data={data01}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={50}
            fill="#0088FE"
          />
          <Pie
            data={data02}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            fill="#00C49F"
            label
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
