import React, { useCallback } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Grid } from "@mui/material";
import NativeSelect from "@material-ui/core/NativeSelect";
import img1 from "../../asset/dash_vendiv.png";
import Box from "@mui/material/Box";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import "./Admin.css";
import { MenuProps, options } from "./utils";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const drawerWidth = 110;
const color = localStorage.getItem("color");
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between",
  },
  popover: {
    pointerEvents: "none",
    width: 400,
    color: "red",
  },

  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth + 260}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    backgroundColor: "#fff",
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#fcb426",
  },

  drawerHeader: {
    display: "flex",
    alignItems: "center",
    // padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    minHeight: "0px !important",
  },
  content: {
    flexGrow: 1,
    backgroundColor: "#fff",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -150,
  },
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
  contentShift: {
    marginLeft: "-57px",
    backgroundColor: "#fff",
    padding: "4px 24px !important",
  },

  title: {
    flexGrow: 1,
  },
}));

export default function Admin(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [selected, setSelected] = React.useState([]);
  const [selectedFilm, setSelectedFilm] = React.useState([]);
  const [selectedClient, setSelectedClient] = React.useState("DECATHLON");
  const [state, setState] = React.useState({
    claimA: true,
    claimB: false,
    claimC: false,
    riskA: false,
    riskB: true,
    riskC: false,
    benchmarkA: true,
    benchmarkB: false,
    benchmarkC: false,
  });

  const userList = [
    {
      clientName: "DECATHLON",
      userName: ["All", "Namita Gaikwad", "Shreyas Shinde"],
    },
    {
      clientName: "SAINT-GOBIN",
      userName: ["All", "Ashwin Date", "Satish Ingale"],
    },
    {
      clientName: "NESTLE",
      userName: ["All", "Ankush Maherwal", "Kshitija Kadam"],
    },
    { clientName: "MURRAY", userName: ["All", "Kirti Kumar", "Manjiri Sule"] },
  ];
  const handleChange = (event) => {
    setSelectedClient(event.target.value);
  };
  const handleChangeChecked = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleDrawerOpen = useCallback(() => setOpen(!open), [open]);

  const handleDrawerClose = useCallback(() => setOpen(false));

  let users = userList.filter((user) => {
    return user.clientName === selectedClient;
  });
  const uName = users[0].userName.map((item, i) => {
    return item;
  });
  const handleChangeCheckUser = useCallback((event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected?.length === uName.length ? [] : uName);
      return;
    }
    setSelected(value);
  });

  const isAllSelected = uName.length > 0 && selected?.length === uName.length;
  return (
    <div className={classes.root}>
      <CssBaseline />

      <Toolbar className="toolbar-height">
        <div className={classes.drawerHeader}>
          {open === false && (
            <IconButton
              onClick={handleDrawerOpen}
              className="left-yello-menu"
              style={{ backgroundColor: color ? color : "#fcb426 !important" }}
            >
              {theme.direction === "ltr" ? (
                <MenuIcon className="left-menu-icon-yellow " />
              ) : null}
            </IconButton>
          )}
        </div>
      </Toolbar>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Box className="img-holder1">
            <img className="img-fluid" src={img1} alt="" />
          </Box>
          <div className="box-shadow-menu-button">
            <IconButton onClick={handleDrawerClose} className="left-white-menu">
              {theme.direction === "ltr" ? <MenuIcon /> : null}
            </IconButton>
          </div>
        </div>
      </Drawer>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
        style={{ height: "100vh" }}
      >
        <div className={classes.drawerHeader} />
        <h2>Admin Dashboard</h2>

        <Grid container>
          <Grid item xs={12} sm={6}>
            <FormControl className="formControl-width">
              <FormLabel component="legend">Client</FormLabel>
              <NativeSelect
                className="formControl-width"
                id="demo-customized-select-native"
                value={selectedClient}
                onChange={handleChange}
              >
                {userList.map((company, i) => {
                  return (
                    <option key={company.clientName}>
                      {company.clientName}
                    </option>
                  );
                })}
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            {" "}
            <FormControl className="formControl-width">
              <FormLabel component="legend">Users</FormLabel>
              <Autocomplete
                multiple
                id="size-small-standard-multi"
                size="small"
                options={uName}
                disableCloseOnSelect
                onChange={(e, name) => {
                  setSelectedFilm(name);
                }}
                getOptionLabel={(option) => option}
                renderOption={(option, state) => {
                  const selectFilmIndex = selectedFilm.findIndex(
                    (name) => name.toLowerCase() === "all"
                  );
                  if (selectFilmIndex > -1) {
                    state.selected = true;
                  }
                  return (
                    <React.Fragment>
                      <Checkbox
                        style={{ marginRight: 8 }}
                        checked={state.selected}
                        color="primary"
                      />
                      {option}
                    </React.Fragment>
                  );
                }}
                style={{ width: 200 }}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
              />
              {/* <Select
                labelId="mutiple-select-label"
                multiple
                value={selected || []}
                onChange={handleChangeCheckUser}
                renderValue={(selected) => selected}
                MenuProps={MenuProps}
              >
                <MenuItem value="all">
                  <ListItemIcon>
                    <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      checked={isAllSelected}
                      indeterminate={
                        selected?.length > 0 && selected.length < options.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.selectAllText }}
                    primary="Select All"
                  />
                </MenuItem>
                {users[0].userName.map((option) => (
                  <MenuItem key={option} value={option}>
                    <ListItemIcon>
                      <Checkbox checked={selected?.includes(option)} />
                    </ListItemIcon>
                    <ListItemText primary={option.title}>{option}</ListItemText>
                  </MenuItem>
                ))}
              </Select> */}
            </FormControl>
          </Grid>
        </Grid>

        {/* --------------------------- */}

        <Grid container spacing={3} style={{ marginTop: "5%" }}>
          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">Claims Analytics:</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.claimA}
                      onChange={handleChangeChecked}
                      name="claimA"
                      color="primary"
                    />
                  }
                  label="Claims Settlement Report"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.claimB}
                      onChange={handleChangeChecked}
                      name="claimB"
                      color="primary"
                    />
                  }
                  label="Claims Rejected Report"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.claimC}
                      onChange={handleChangeChecked}
                      name="claimC"
                      color="primary"
                    />
                  }
                  label="Claims Ad-Hoc Report"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">Risk Analytics:</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.riskA}
                      onChange={handleChangeChecked}
                      name="riskA"
                      color="primary"
                    />
                  }
                  label="IRM Risk Management Report"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.riskB}
                      onChange={handleChangeChecked}
                      name="riskB"
                      color="primary"
                    />
                  }
                  label="IRM Ad-Hoc Report"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.riskC}
                      onChange={handleChangeChecked}
                      name="riskC"
                      color="primary"
                    />
                  }
                  label="IRM Monthly Report"
                />
              </FormGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">Benchmarking:</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.benchmarkA}
                      onChange={handleChangeChecked}
                      name="benchmarkA"
                      color="primary"
                    />
                  }
                  label="Benchmarking Report 01"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.benchmarkB}
                      onChange={handleChangeChecked}
                      name="benchmarkB"
                      color="primary"
                    />
                  }
                  label="Benchmarking Report 02"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.benchmarkC}
                      onChange={handleChangeChecked}
                      name="benchmarkC"
                      color="primary"
                    />
                  }
                  label="Benchmarking Report 03"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Button variant="contained" style={{ backgroundColor: "#fcb426" }}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </main>
    </div>
  );
}
