import React from "react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import RiskImg from "../../asset/ClaimsAnalytics.png";

export default function RiskAnalyticsTab() {
  return (
    <Grid container>
      <Grid
        className="mb-30 card-grid1 ff-m"
        sx={{ position: "relative" }}
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
      >
        <Box className="titleRisk">
          <img src={RiskImg} class="img-fluid-menu" alt="..."></img>
        </Box>
      </Grid>
    </Grid>
  );
}
